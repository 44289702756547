<template>
    <div class="schedling">

        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader @handRegister="handRegister" @handFromshow="handFromshow" :titlename="titlename"></Tabheader>
        </div>
        

         <div class="attheadfrom" v-show="flag">
            <el-form :inline="true" size="small" :model="formInline" class="demo-form-inline">
                <el-form-item :label="$t('devtable.gzmc')">
                    <el-input v-model="formInline.name" :placeholder="this.$t('devtable.gzmc')"></el-input>
                </el-form-item>
                <!-- <el-form-item label="开始时间">
                    <el-date-picker
                    v-model="value1"
                    type="date"
                    placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker
                    v-model="value1"
                    type="date"
                    placeholder="选择日期">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">{{this.$t('devtable.cx')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- <Tables :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :configs="configs" :operation="operation"></Tables> -->
        
        <div style="padding:20px">
            <el-table
                :data="tableData"
                border
                style="width: 100%">

                <el-table-column
                v-if="flags.ishow"
                type="index"
                width="120"
                align="center"
                :label="$t('devtable.xh')"
                >
                </el-table-column>

                <el-table-column
                prop="name"
                :label="$t('devtable.gzmcH')"
                align="center"
                >
                </el-table-column>
                
                <el-table-column
                prop="order_name"
                :label="$t('devtable.bcjgz')"
                >
                    <template slot-scope="scope">
                        <p>{{scope.row.order_name}}</p>
                        <span style="font-size: 12px;color:#999999;">{{scope.row.workingday}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                prop="employee"
                align="center"
                :label="$t('devtable.yyfw')">
                </el-table-column>
                <!-- 操作 -->
                <el-table-column
                    :label="$t('tablename.cz')"
                    align="center"
                    >
                    <template slot-scope="scope">
                        <el-button @click="handleClickedit(scope.row)" size="small" type="primary" >{{$t('tablename.bj')}}</el-button>
                        <el-button  @click="handleClickdel(scope.row)" size="small" type="danger" >{{$t('tablename.sc')}}</el-button>
                    </template>
                    </el-table-column>
            </el-table>
        </div>
        
        <Schedulingdolg ref="schedulingdolg"></Schedulingdolg>
        <Schedulingedit ref="schedulingedit"></Schedulingedit>
        <Cleadr ref="cleadr" @clearhand="clearhand" :data="clearldata"></Cleadr>
    </div>
</template>
<script>
// import Tables from '@/components/table'
import Cleadr from './components/cleadr'
import Tabheader from './components/tabheader'
import Schedulingdolg from './components/schedulingdolg'
import Schedulingedit from './components/schedulingdolg2'
export default {
    components:{
        // Tables,
        Tabheader,
        Schedulingdolg,
        Cleadr,
        Schedulingedit
    },
    data(){
        return{
            clearldata:{},
            input:'',
            value1:'',
            flag:false,
            formInline: {
                name: '',
            },
            titlename:{
                name:this.$t('navLeft.addR'),
                placeholdername:'请点击查询'
            },
            
            tableData: [
            ],
            flags: {
                show: true, // 多选是否显示
                ishow: true // 序号是否显示
            },
            // 分页数据
            configs: {
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
            // operation: {
            //     width:'200px',
            //     data:[
            //     {
            //         name: '修改',
            //         type: 'danger'
            //       },
            //       {
            //         name: '删除',
            //         type: 'danger'
            //       },
            //     ]
            // },
            ryuanlist:[],
            UserInfo:''
        }
    },
    computed:{
        tableLabel:function(){ 
            return [
                {
                    prop: 'name',
                    label: this.$t('devtable.gzmc'),
                    align: 'center'
                },
                {
                    prop: 'order_name',
                    label: this.$t('devtable.bcjgz'),
                    align: 'center'
                },
                {
                    prop: 'employee',
                    label: this.$t('devtable.yyfw'),
                    align: 'center'
                },
                
            ]
        },
    },
    mounted(){
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getryuanList()
        
    },
    methods:{
        // 人员资料的列表
        getryuanList(){
            let data={
                method:'co.person.list',
                page_index:1,
                page_size:1000, 
                name:'',
                mobile:'',
                card_no:'',
                agent_id:this.UserInfo.agent_id,
                dept_id:'',
                number:'',
            }
            this.$serve(data).then(res=>{
                this.ryuanlist = res.data.data
            }).then(res=>{
                this.getschuiList()
            })
        },
        handRegister(){
            this.$refs.schedulingdolg.centerDialogVisible=true
        },  
        handFromshow(){
            this.flag = !this.flag
        },
        getschuiList(v,name){
            let data ={
                method:'att.arranges.get',
                agent_id:this.UserInfo.agent_id,
                name:name?name:"",
                page_index:v?v:"",
                page_size:10
            }
            this.$serve(data).then(res=>{
                console.log(`999`,res)
                if(res.data.code==0){
                    let date=res.data.data.records
                    date.forEach(element => {
                        if(element.workingday){
                            var workingday=[]
                            var workingday1=[]
                            workingday=element.workingday.split(',')
                            workingday.forEach(el=>{
                                // console.log(`65`,el)
                                if(el==0){
                                    workingday1.push(this.$t('devtable.xqy'))
                                }else if(el==1){
                                    workingday1.push(this.$t('devtable.xqe'))
                                }else if(el==2){
                                    workingday1.push(this.$t('devtable.xqs'))
                                }else if(el==3){
                                    workingday1.push(this.$t('devtable.xqss'))
                                }else if(el==4){
                                     workingday1.push(this.$t('devtable.xqw'))
                                }else if(el==5){
                                    workingday1.push(this.$t('devtable.xql'))
                                }else if(el==6){
                                    workingday1.push(this.$t('devtable.xqq'))
                                }
                            })
                            element.workingday=workingday1.join('、')
                        }
                        if(element.employee){
                            var employee = []
                            var employee1 = []
                            employee = element.employee.split(',')
                            employee.forEach(el=>{
                                this.ryuanlist.forEach(element=>{
                                    if(el == element.id){
                                        employee1.push(element.name)
                                    }
                                })
                            })
                            console.log(employee1)
                            element.employee =employee1.join(' 、')
                        }
                    });
                    this.tableData = res.data.data.records
                }
            })
        },
        onSubmit(){
            this.getschuiList('',this.formInline.name)
        },
        // 删除后回调列表接口
        clearhand(){
            this.getschuiList()
        },
        // 删除
        
        handleClickdel(item){
            console.log(item)
            this.clearldata={
                    id:item.id,
                    method:"att.arrange.delete"
                }
                this.$refs.cleadr.dialogVisible = true
        },
        // 编辑
        handleClickedit(item){
            // console.log(item)
            this.$refs.schedulingedit.getryuzil(item)
            this.$refs.schedulingedit.centerDialogVisible = true
        }
    }
}
</script>
<style lang="less" scoped>
.schedling{
    padding: 20px;
    background-color: #fff;
    .attheadfrom{
        padding: 20px;
        margin-top: 10px;
        
    }
}

 
</style>