<template>
    <div>
        <el-dialog
        center
        :title="$t('devtable.tsxx')"
        :visible.sync="dialogVisible"
        width="30%"
        append-to-body
        :before-close="handleClose">
        <el-form :model="delform">
            <el-form-item :label="$t('devtable.yhm')" :label-width="formLabelWidth">
            <el-input v-model="delform.user_name_input" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$t('devtable.mm')" :label-width="formLabelWidth">
            <el-input v-model="delform.user_pass_input" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="dialogVisiblefalse">取 消</el-button> -->
            <el-button type="primary" @click="userdialogVisibletrue">{{$t('tablename.qd')}}</el-button>
        </span>
        </el-dialog>
    </div>
</template>
<script>
var loginObj=JSON.parse(window.localStorage.getItem('user'));
export default {
    data (){
        return{
            dialogVisible:false,
            delform: {
              user_name_input: '',
              user_pass_input: ''
            },
            formLabelWidth:'100px',
        }
    },
    props:{
        data:Object,
    },
    methods:{
          userdialogVisibletrue(){
            this.dialogVisible=false;
            if(this.delform.user_name_input != loginObj.user_name_save){
                this.$message({
                type: 'warning',
                message: this.$t('devtable.yhmsryw')
                })
                return false;
            }
            if(this.delform.user_pass_input != loginObj.user_pass_save){
                this.$message({
                type: 'warning',
                message: this.$t('devtable.yhmmsryw')
                })
                return false;
            }
            
            this.$serve(this.data).then(res => {    
                console.log(`9099`,res)      
                if(res.data.code == 0){
                    this.$message({
                        message: this.$t('devtable.sccg'),
                        type: 'success'
                    })  
                    this.$emit("clearhand")   
                }else{
                    this.$message({
                    message:  this.$t('devtable.scsb'),
                    type: 'warning'
                    }) 
                }
            });
            
        },
        handleClose(){
            this.dialogVisible= false
        }
    }
}
</script>