<template>
    <div>
        <el-dialog
            :title="$t('devtable.xgbbgz')"
            :visible.sync="centerDialogVisible"
            width="980px"
            append-to-body
            center>
            
            <div style="margin-left:20px;">
                <el-row style="display: flex;width:250px;margin-bottom:20px">
                    <span style="flex:5;line-height: 32px; ">{{$t('devtable.gzmc')}}</span>
                    <el-input size="small" v-model="name" :placeholder="$t('devtable.slbb')" style="flex:12;"></el-input>
                </el-row>
                <el-row>
                    <span>{{$t('devtable.xzbc')}} </span>
                     <el-select v-model="order_id" size="small" :placeholder="$t('devtable.qxz')">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-row>
            </div>

            <div style="margin-left:20px;margin-top:20px;display: flex">
                <el-row style="flex:1;display: flex;margin-right:20px">
                    <span style="flex:1;line-height: 32px;flex: 2 1 0%;">{{$t('devtable.gzr')}} </span>
                    <el-select v-model="workingday" size="small" multiple :placeholder="$t('devtable.qxz')" style="margin-left:8px;margin-right:15px;flex:5">
                        <el-option  
                        v-for="item in optionst"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-row  style="flex:1;line-height: 32px;"> <el-button type="primary" size="mini">{{$t('devtable.tjH')}}</el-button></el-row>
                </el-row>
                <el-row style="flex:1;display: flex">
                    <!-- <span style="flex:1;line-height: 32px;">节假日： </span>
                    <el-select v-model="value11" size="small" multiple :placeholder="$t('devtable.qxz')" style="margin-left:8px;margin-right:15px;flex:5">
                        <el-option  
                        v-for="item in optionst"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-row  style="flex:1;line-height: 32px;"> <el-button type="primary" size="small">添加</el-button></el-row> -->
                </el-row>
            </div>

            <div class="schdolgfoot">
                <div class="yuang"> {{$t('devtable.yg')}}</div>
                <div class="staff">
                    <div class="staffleft">
                        <div class="stafflefttop">
                            <span style="width:80px;line-height: 32px;">{{$t('devtable.bm')}} </span>

                            <SelectSingle @handleCheckChange="handleCheckChange"></SelectSingle>

                            <!-- <el-select v-model="value" :placeholder="$t('devtable.qxz')" size="small" style="flex:4">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select> -->
                        </div>
                        <el-row  style="display: flex;padding-left:55px">
                            <el-input size="small" v-model="input_name" :placeholder="$t('devtable.xm')" style="flex:3;margin-right:20px"></el-input>
                            <el-row  style="flex:2;line-height: 32px;"> <el-button type="primary" @click="searchWithName" size="small">{{$t('devtable.cx')}}</el-button></el-row>
                        </el-row>
                    </div>
                    <div class="staffright">
                        <p>{{$t('devtable.yxyg')}}</p>
                    </div>
                </div>
                <div style="padding:25px">
                    <Tablelave ref="transTab"></Tablelave>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handSever">{{$t('tablename.qd')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Tablelave from './tablelave'
import SelectSingle from '@/components/selectree/selectchoice'
export default {
    components:{
        Tablelave,
        SelectSingle
    },
    data(){
        
        return{
            input_name:'',
            input:'',
            value11: [],
            name: '',
            order_id:'',
            workingday:[],
            value:'',
            centerDialogVisible: false,
            options: [],
            optionst: [{
            value: '0',
            label: this.$t('devtable.xqy')
            }, {
            value: '1',
            label: this.$t('devtable.xqe')
            }, {
            value: '2',
            label: this.$t('devtable.xqs')
            }, {
            value: '3',
            label: this.$t('devtable.xqss')
            }, {
            value: '4',
            label: this.$t('devtable.xqw')
            },{
            value: '5',
            label: this.$t('devtable.xql')
            },{
            value: '6',
            label: this.$t('devtable.xqq')
            }],
            datas:[] ,
            vluass:[1,4],
            ryuanlist:[],
            tableData_b:[],
            id:'',
            UserInfo:''
        }
    },
    mounted(){
        // this.generateData()
         this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getbancilList()
    },
    methods:{
        getryuzil(item){
            this.id = item.id
            this.tableData_b=[]
            let data ={
                method:'co.person.list',
                page_index:1,
                page_size:1000, 
                name:'',
                mobile:'',
                card_no:'',
                agent_id:this.UserInfo.agent_id,
                dept_id:'',
                number:'',
            }
            this.$serve(data).then(res=>{
                this.ryuanlist = res.data.data
            }).then(res=>{
                this.getschuiList2(item)
            })
        },
        // 修改 获取排班信息
        getschuiList2(item){
            let data={
                method:'att.arrange.get',
                id:item.id
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    let data = res.data.data
                    this.name = data.name
                    this.order_id = data.order_id
                    this.workingday = data.workingday.split(',')
                    var employee = data.employee.split(',')
                    employee.forEach(element=>{
                        this.ryuanlist.forEach(el=>{
                            if(element == el.id){
                                this.tableData_b.push(el)
                                this.$refs.transTab.tableData_b= this.tableData_b
                            }
                        })
                    })
                    
                }
            })
        },
        handleCheckChange(sel_dept){
            console.log(sel_dept);
            this.dept_id = sel_dept;
            this.$refs.transTab.getEmployeeListWithDept(sel_dept,this.input_name);
        },
        searchWithName(){
            if(this.input_name.length == 0){
                this.$message({
                    type: 'warning',
                    message:this.$t('devtable.qsrygxm')
                })
            }else if(this.dept_id.length == 0){
                this.$message({
                    type: 'warning',
                    message: this.$t('devtable.qxxzbm')
                })
            }else{
                this.$refs.transTab.getEmployeeListWithDept(this.dept_id,this.input_name);
            }
        },

        // 获取班次列表
        getbancilList(){
            let data={
                method:'att.orders.get',
                agent_id:this.UserInfo.agent_id,
                name:name?name:'',
                page_index:1,
                page_size:1000
           }
           this.$serve(data).then(res=>{
               let data = res.data.data.records
               data.forEach(element => {
                   let ss={
                       value:element.id,
                       label:element.name
                   }
                   this.options.push(ss)
               });
           })
        },

        // 增加排班
        handSever(){
            let employee =[]
            this.$refs.transTab.tableData_b.forEach(el=>{
                console.log(el)
                employee.push(el.id)
            })
            if(employee.length == 0){
                this.$message({
                    type: 'warning',
                    message: this.$t('devtable.qxzyg')
                })
                return false;
            }
            let data={
                id:this.id,
                method:'att.arrange.edit',
                agent_id: this.UserInfo.agent_id,
                name:this.name,
                order_id:this.order_id,
                workingday:this.workingday.toString(),
                holiday:'',
                employee:employee.toString()
            }
            this.$serve(data).then(res=>{
                if(res.data.code == 0){
                    this.$message({
                        type: 'success',
                        message: this.$t('devtable.xgcg')
                    })
                    this.centerDialogVisible = false;
                    this.$parent.getschuiList()
                }else{
                    this.$message({
                        type: 'error',
                        message: this.$t('devtable.xgsb')
                    })                    
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
.schdolgfoot{
    border: 1px solid #ccc;
    position: relative;
    height: 435px;
    margin-top: 20px;
    .yuang{
        width: 50px;
        height: 20px;
        text-align: center;
        background-color: #fff;
        position: absolute;
        top:-10px;
        left: 20px;
    }
    .staff{
        display: flex;
        padding: 20px;
        .staffleft{
            flex: 1;
            .stafflefttop{
                display: flex;
                margin-bottom: 20px;
                margin-left: 10px;
            }
        }
        .staffright{
            flex: 1;
            p{
                text-align: center;
            }
        }
    }
}
</style>